import AOS from "aos";
import "aos/dist/aos.css";

import "bootstrap"; // js
import "./main.scss";
import navbar from "./components/navbar/navbar.js";

window.onload = function() {
	AOS.init();
	navbar();
	const brandName = document.querySelector(".brand-name");
	const aboutItem = document.querySelector(".about-item");
	const homeItem = document.querySelector(".home-item");
	const headerBtn = document.querySelector(".header-btn");
	const contactForm = document.querySelector("#contact-form");
	const formAlertSuccess = document.querySelector("#form-alert-success");
	const formAlertFailure = document.querySelector("#form-alert-failure");

	brandName.addEventListener("click", (e) => {
		const activeElem = document.querySelector(".nav-item.active");

		activeElem.classList.remove("active");
		homeItem.classList.add("active");
	});

	headerBtn.addEventListener("click", (e) => {
		const activeElem = document.querySelector(".nav-item.active");

		activeElem.classList.remove("active");
		aboutItem.classList.add("active");
	});

	// contact form
	contactForm.addEventListener("submit", (e) => {
		e.preventDefault();
		console.log("working");
		const form = e.target;
		const data = new FormData(form);
		const xhr = new XMLHttpRequest();
		xhr.open(form.method, form.action);
		xhr.setRequestHeader("Accept", "application/json");
		xhr.onreadystatechange = () => {
			if (xhr.readyState !== XMLHttpRequest.DONE) return;
			if (xhr.status === 200 || xhr.status === 201) {
				form.reset();
				console.log("submitted");
				showFormAlert(true);
				// console.log(xhr.response, xhr.responseType);
			} else {
				showFormAlert(false);
				console.log(xhr.status, xhr.response, xhr.responseType);
			}
		};
		xhr.send(data);
	});

	function showFormAlert(success) {
		if (success) {
			formAlertSuccess.classList.remove("d-none");
			setTimeout(() => {
				formAlertSuccess.classList.add("d-none");
			}, 5000);
		} else {
			formAlertFailure.classList.remove("d-none");
			setTimeout(() => {
				formAlertFailure.classList.add("d-none");
			}, 5000);
		}
	}
};
